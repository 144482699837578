import React, { FC, useContext, useState, useEffect, StrictMode } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Importing CSS files
import "App.css";
import "./index.css";

import { host } from "config";
// Importing components
import Home from "components/home/Home";
import Dashboard from "./components/dashboard/Dashboard";
import LogIn from "components/login/Login";
import SignUp from "components/signup/SIgnUp";
import FormInputComponent from "./components/enrollments/enroll";

import FileUploadComponent from "./components/upload/upload2";

import ClaimsUpload from "./components/claimsUpload/claimsUpload";

import { AuthProvider } from "./components/authProvider/authProvider";
import Copyright from "./components/assets/Copyright";
import Copyright2 from "./components/footerpt2/Copyright2";
import Header from "./components/assets/Header12";
import AdminPortal from "components/admin/AdminPortal";

import AdminCards from "components/admin/adminCards/adminCards";

import AdminAddUser from "components/admin/addUserAdmin/AddUserAdmin";

import AdminSearch from "components/admin/adminSearch/AdminSearch";

import AdminGroupSearch from "components/admin/adminGroupChecker/adminGroupChecker";

import Claims from "components/claims/claims";
import ClaimsLookupSystem from "components/claimsLookup/claimsLookup";

import LossRatioTable from "components/lossRatioTable/LossRatio";
import EmployeeMaintenance from "components/employeeMaintenance/employeeMain";

import Census from "components/census/census";
import AcctProfile from "components/AcctProfile/acctProfile";
import SideNavBar from "components/SideNavBar/SideNavBar";
import ThreeCards from "components/basePage/ThreeCards";

import Reports from "components/commissionsPage/commissionsPage";

import Commissions from "components/commissionsPage/commissionsPage";

import Payments from "components/payments/PaymentOptions";

import Invoice from "components/invoicePage/InvoiceTable";

// import Eligibility from "components/eligibilityLookup/eligibilityLook";
import EligibilityLookUp from "components/eligibilityLookup/eligibilityLookUp";

import PolicyAndCerts from "components/policyAndCerts/PolicyAndCerts";

import GapPage from "components/gapPage/gapPage";

import OptiEnhance from "components/optienhance/OptiEnhance";

import UploadPage from "components/censusUpload/UploadPage";
import MFAEnroll from "components/mfa/mfaForm";

//import Main from 'components/main/main'

import Auth from "components/auth/Auth";
import ImplementationStatus from "components/implementationStatus/implementationStatus";
import VerifyCensus from "components/verifyCensus/verifyCensus";
import ConfirmMFAEnroll from "components/mfa/confirmMFAEnroll";
import SignInMFA from "components/mfa/signInMFA";
import AcctManagement from "components/AcctManagement/acctManagement";
import GroupImplementation from "components/groupImplementation/groupImplementation";

//import { useForm } from 'react-hook-form';
let dummyHost = host;

const App: FC = () => {
  //console.log("one");

  //const { register, handleSubmit, control, setValue} = useForm();

  const [isNavBarOpen, setNavBarOpen] = useState(false);
  const [isOnSpecialPage, setIsOnSpecialPage] = useState(false);

  //const win: Window = window;
  // const [isOnPaymentPage, setIsOnPaymentPage] = useState(false);

  //const [isOnOptiEnhancePage, setIsOnOptiEnhancePage] = useState(false);

  const toggleNavBar = () => {
    setNavBarOpen(!isNavBarOpen);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      const path = window.location.pathname;
      setIsOnSpecialPage(path === "/payments" || path === "/gapPage");

      if (isOnSpecialPage) {
        setNavBarOpen(false);
      }
    };

    window.addEventListener("popstate", handleRouteChange);

    // Initialize
    handleRouteChange();

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, [isOnSpecialPage]);

  return (
    <div className="whole">
      {/* AuthProvider is used here to wrap the Router, so auth context is available to all child components */}
      <AuthProvider>
        {/* <button onClick={toggleNavBar}>Toggle Navigation</button> */}
        <Router>
          {!isOnSpecialPage && <SideNavBar isOpen={isNavBarOpen} />}

          <div className={`main-content ${isNavBarOpen ? "shifted" : ""}`}>
            {!isOnSpecialPage && <Header toggleNavBar={toggleNavBar} isNavBarOpen={isNavBarOpen} />}

            {/* This div provides the space */}
            <div className="bigBoy" style={{ paddingTop: "15px" }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/login" element={<LogIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/implementationStatus" element={<ImplementationStatus />} />
                <Route path="/upload" element={<FileUploadComponent />} />

                <Route path="/payments" element={<Payments />} />
                <Route path="/optiEnhance" element={<OptiEnhance />} />
                <Route path="/signInMFA" element={<SignInMFA />} />
                <Route path="/accountManagement" element={<AcctManagement />} />
                <Route path="/gapPage" element={<GapPage />} />

                <Route element={<Auth allowedRoles={[]} />}>
                  <Route path="/mfaEnroll" element={<MFAEnroll />} />
                  <Route path="/confirmMFAEnroll" element={<ConfirmMFAEnroll />} />
                  <Route path="/basePage" element={<ThreeCards />} />
                  <Route path="/acctProfile" element={<AcctProfile />} />
                </Route>
                <Route element={<Auth allowedRoles={["admin"]} />}>
                  <Route path="/admin" element={<AdminPortal />} />
                  <Route path="/adminPanel" element={<AdminCards />} />
                  <Route path="/addUser" element={<AdminAddUser />} />
                  <Route path="/adminSearch" element={<AdminSearch />} />
                  <Route path="/adminGroupSearch" element={<AdminGroupSearch />} />
                </Route>
                <Route element={<Auth allowedRoles={["admin", "internal_ugp"]} />}>
                  <Route path="/claimsUpload" element={<ClaimsUpload />} />
                  <Route path="/groupImplementation" element={<GroupImplementation />} />
                  <Route path="/eligibility" element={<EligibilityLookUp />} />
                  <Route path="/lossRatio" element={<LossRatioTable />} />
                </Route>
                <Route element={<Auth allowedRoles={["admin", "broker", "employer", "internal_ugp"]} />}>
                  <Route path="/enroll" element={<FormInputComponent />} />
                  <Route path="/censusUpload" element={<UploadPage />} />
                  <Route path="/census" element={<Census />} />
                  <Route path="/employeeMaintenance" element={<EmployeeMaintenance />} />
                  <Route path="/claimsLookup" element={<ClaimsLookupSystem />} />
                  <Route path="/claims" element={<Claims />} />
                  <Route path="/invoice" element={<Invoice />} />
                  <Route path="/policyAndCerts" element={<PolicyAndCerts />} />
                </Route>
              </Routes>
            </div>
          </div>

          {!isOnSpecialPage && (
            <div className="copyright">
              <Copyright />
            </div>
          )}
          {/* <div className="copyright">{isOnPaymentPage && <Copyright2 />}</div> */}
        </Router>
      </AuthProvider>
    </div>
  );
};

export default App;
